import { ReactNode } from "react";
import {
  PrimaryNavigationNode,
  Footer as FooterPreprType,
  DialogForm,
  WhitepaperDialogProps,
} from "@/services/prepr/queries/getLayoutContent";
import Navbar from "../Navbar";
import Footer from "../Footer";

export type LayoutProps = {
  children: ReactNode;
  layoutProps: {
    navigation: PrimaryNavigationNode[];
    dialogs: DialogForm[];
    whitepaperDialog?: WhitepaperDialogProps | null;
    footer?: FooterPreprType;
  };
};

export const LayoutContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  /* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */
  <main className="tablet:pt-16 desktop-s:pt-[84px] overflow-clip">{children}</main>
);
export default function Layout({ children, layoutProps }: LayoutProps) {
  return (
    <>
      <Navbar navigation={layoutProps.navigation} />
      <LayoutContainer>{children}</LayoutContainer>
      <Footer footer={layoutProps.footer} />
    </>
  );
}
