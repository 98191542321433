import { themePreset, borderGradientBase } from "@hiltermann/tailwind";
import type { Config } from "tailwindcss";

const config: Config = {
  presets: [themePreset],
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./stories/**/*.{js,ts,jsx,tsx}",
    "./utilities/**/*.{js,ts,jsx,tsx}",
    "../../packages/components/src/**/*.{js,ts,jsx,tsx}",
  ],
  plugins: [borderGradientBase],
  theme: {
    screens: themePreset.theme?.screens,
  },
};

export default config;
