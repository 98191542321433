"use client";

import { type FC, useCallback, useId, useState } from "react";
import { type Accept, type FileWithPath, useDropzone } from "react-dropzone";
import clsx from "clsx";
import { Upload as FileUploadIcon } from "@hiltermann/components/icons";
import { Button } from "../../Button/Button";
import { Checkmark } from "../../../icons";
import { Label } from "../../Label";
import { UnControlledError } from "../../Error";

export interface Texts {
  dragActive: string;
  selectFileBefore: string;
  uploadButton: string;
  removeButton: string;
  selectFileAfter?: string;
  uploadDescription?: string;
  label?: string;
  labelDescription?: string;
  optionalLabel?: string;
}

export interface UnControlledFileUploadProps {
  name: string;
  onFilesChange: (files: FileWithPath[]) => void;
  texts: Texts;
  acceptedFileTypes?: Accept;
  disabled?: boolean;
  error?: string;
  files?: FileWithPath[];
  maxFiles?: number;
  maxSize?: number;
  onRemoveFile?: (file: FileWithPath) => void;
}

export const UnControlledFileUpload: FC<UnControlledFileUploadProps> = ({
  acceptedFileTypes,
  disabled,
  error,
  files,
  maxFiles,
  maxSize = 1024000000,
  name,
  onFilesChange,
  onRemoveFile,
  texts,
}) => {
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const hasUploadedFiles = files && files.length > 0;
  const isSingleFileUpload = maxFiles && maxFiles === 1;
  const filesReachedMax = maxFiles && files && files.length >= maxFiles;
  const canAddMoreFiles = maxFiles && hasUploadedFiles && files && files.length < maxFiles;
  const id = useId();

  const onDrop = useCallback(
    (uploadedFiles: FileWithPath[]) => {
      const totalFiles = hasUploadedFiles ? [...uploadedFiles, ...files] : uploadedFiles;
      const totalFilesReachedMax = maxFiles && totalFiles.length >= maxFiles;

      if (totalFilesReachedMax) {
        const limitedFiles = totalFiles.slice(0, maxFiles);

        onFilesChange && onFilesChange(limitedFiles);
        setIsDragActive(false);
        return;
      }

      if (isSingleFileUpload) {
        onFilesChange && onFilesChange(uploadedFiles);
        setIsDragActive(false);
        return;
      }

      onFilesChange && onFilesChange(totalFiles);
      setIsDragActive(false);
    },
    [onFilesChange, maxFiles, files, hasUploadedFiles, isSingleFileUpload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFileTypes,
    onDrop,
    noClick: true,
    maxSize: maxSize,
    disabled,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropRejected: (files: any[]) => {
      files.map((file, i) => {
        if (file.errors[0].code === "file-invalid-type") {
          setLocalError("Bestanden zijn niet van een geaccepteerde bestandstype");
        } else if (file.errors[0].code === "file-too-large") {
          setLocalError("Bestanden zijn te groot.");
        }
      });
    },
    onDropAccepted: () => setLocalError(null),
  });

  const handleFileOpenDialog = () => {
    if (disabled) return;
    if (isSingleFileUpload && hasUploadedFiles) return;
    if (filesReachedMax) return;

    open();
  };

  const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement>, file: FileWithPath) => {
    event.stopPropagation();
    onRemoveFile && onRemoveFile(file);
  };

  const containerClassNames = clsx(
    !hasUploadedFiles && "bg-grey-light border font-sans border-dashed",
    hasUploadedFiles && "rounded-lg border",
    "border-solid dropzone item-center flex min-h-[122px] flex-col items-center justify-around px-6",
    isSingleFileUpload && !hasUploadedFiles && !disabled && filesReachedMax && "cursor-pointer",
    canAddMoreFiles && "cursor-pointer",
    filesReachedMax && "cursor-default",
    !maxFiles && !disabled && "cursor-pointer",
    maxFiles && !hasUploadedFiles && "cursor-pointer",
    isSingleFileUpload && hasUploadedFiles && "cursor-default",
    disabled && "cursor-not-allowed opacity-50",
    "border-1 border-grey-asphalt rounded-lg p-10"
  );

  return (
    <>
      {texts.label && (
        <Label
          label={texts.label}
          description={texts.labelDescription}
          id={id}
          optionalLabelText={texts.optionalLabel}
        />
      )}
      <div {...getRootProps()} id={id}>
        <div onClick={handleFileOpenDialog} className={containerClassNames}>
          {isDragActive && <p className="font-bold">{texts.dragActive}</p>}
          {!hasUploadedFiles && !isDragActive && (
            <>
              <input {...getInputProps()} />
              <div className="flex flex-col items-center gap-6">
                <FileUploadIcon width={32} />
                <div className="flex flex-col items-center">
                  <p className="text-base tracking-wide text-red-700 text-center">
                    {texts.selectFileBefore}
                  </p>
                </div>
                <Button type="button" variant="secondary" size="small" fullWidth={false}>
                  {texts.uploadButton}
                </Button>
              </div>
            </>
          )}
          {hasUploadedFiles &&
            files.map((file, index) => (
              <div key={index} className="flex flex-col items-center gap-6">
                <Checkmark width={24} color="green" />
                <div className="ml-3">
                  <div className="font-bold leading-6 text-center">{file.name}</div>
                </div>
                <Button
                  type="button"
                  variant="secondary"
                  size="small"
                  fullWidth={false}
                  onClick={(event) => handleRemoveFile(event, file)}
                >
                  {texts.removeButton}
                </Button>
              </div>
            ))}
        </div>
        {localError && <UnControlledError error={localError} />}
        {error && <UnControlledError error={error} data-testid={name} />}
      </div>
    </>
  );
};
