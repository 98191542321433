import type { ReactElement, FC } from "react";
import { Button } from "../Button/Button";
import { twMerge } from "tailwind-merge";

export type ButtonDuoProps = (PreviousProps & NextProps) | OnlyPreviousProps | OnlyNextProps;

interface PreviousProps {
  previous: () => void;
  previousText: string;
}
interface OnlyPreviousProps extends PreviousProps {
  next?: never;
  nextText?: never;
  nextIcon?: never;
}

interface NextProps {
  next: () => void;
  nextText: string;
  nextIcon?: ReactElement;
}

interface OnlyNextProps extends NextProps {
  previous?: never;
  previousText?: never;
}

interface GeneralProps {
  className?: string;
}

export const FormButtonDuo: FC<ButtonDuoProps & GeneralProps> = ({
  previous,
  previousText,
  next,
  nextText,
  nextIcon,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "grid grid-cols-1 tablet:grid-cols-2 desktop-s:flex desktop-s:justify-end desktop-s:gap-x-10 gap-4",
        className
      )}
    >
      {previous && previousText && (
        <Button
          type="button"
          variant="secondary"
          size="small"
          onClick={previous}
          className="col-start-1 row-start-2 tablet:row-start-1 desktop-s:w-auto"
        >
          {previousText}
        </Button>
      )}
      {next && nextText && (
        <Button
          type="button"
          variant="tertiary"
          size="small"
          onClick={next}
          className="col-start-1 row-start-1 tablet:row-start-1 tablet:col-start-2 desktop-s:w-auto"
          icon={nextIcon}
        >
          {nextText}
        </Button>
      )}
    </div>
  );
};
