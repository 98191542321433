"use client";

import {
  useId,
  type ChangeEvent,
  type FC,
  type FocusEvent,
  type ReactNode,
  type RefObject,
} from "react";
import { RefCallBack } from "react-hook-form";
import { TextInputType } from "../shared/types";
import { Label } from "../../Label";
import { UnControlledError } from "../../Error";
import { twMerge } from "tailwind-merge";
import { isMobileOrTablet } from "../../../utils/isTabletOrMobile";

interface Props {
  name: string;
  value?: string | number;
  className?: string;
  labelClassName?: string;
  defaultValue?: string;
  description?: string;
  disabled?: boolean;
  error?: string | null;
  formatter?: (value: string, previousValue: string) => string;
  formatterOnBlur?: (value: string, previousValue: string) => string;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  inputClassName?: string;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  optionalLabelText?: string;
  placeholder?: string;
  type?: TextInputType;
  children?: ReactNode;
  success?: ReactNode;
  "data-testid"?: string;
  inputPrefix?: React.ReactNode;
  inputPostfix?: React.ReactNode;
  readOnly?: boolean;
}

export const UncontrolledInput: FC<Props> = ({
  className,
  inputClassName,
  labelClassName,
  defaultValue,
  description,
  disabled,
  error,
  formatter,
  formatterOnBlur,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  optionalLabelText,
  placeholder,
  type,
  value,
  onClick,
  success,
  "data-testid": testId,
  inputPrefix,
  inputPostfix,
  readOnly = false,
}) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatter ? formatter(inputValue, "") : inputValue;

    if (onChange) onChange(formattedValue);

    e.target.value = formattedValue;
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formattedOnBlur = formatterOnBlur ? formatterOnBlur(e.target.value, "") : e.target.value;

    if (onBlur) {
      onBlur(formattedOnBlur);
    }

    if (onChange && formatterOnBlur && !isMobileOrTablet()) {
      // This is a workaround to make sure the value is updated after the blur event
      setTimeout(() => {
        onChange(formattedOnBlur);
      }, 0);
    }
  };

  return (
    <div className={className}>
      {label && (
        <Label
          label={label}
          description={description}
          optionalLabelText={optionalLabelText}
          className={labelClassName}
          id={id}
        />
      )}
      <div
        className={twMerge(
          "relative w-full flex flex-row group",
          "border-grey-asphalt text-forms-sm",
          "focus-within:border-secondary-aubergine focus-within:text-primary-black focus-within:border-2 focus-within:font-bold",
          "hover:border-2",
          "box-border h-16 rounded-lg border",
          // extra margin to compensate for border thickness change
          "px-px",
          "hover:px-0",
          "focus-within:px-0",
          "bg-white",
          error && "border-secondary-aubergine text-secondary-aubergine",
          (disabled || readOnly) && "bg-grey-asphalt hover:border-1",
          className
        )}
      >
        {inputPrefix}
        <input
          id={id}
          disabled={disabled}
          ref={inputRef}
          data-testid={testId || `${name}-input`}
          name={name}
          className={twMerge(
            "grow min-w-20",
            "text-primary-black text-sm",
            "rounded-lg bg-transparent",
            "px-6",
            "placeholder:text-grey-asphalt placeholder:font-normal",
            "hover:placeholder:text-grey-asphalt",
            "tablet:text-forms-md font-sans font-semibold outline-none",
            inputClassName,
            type === "number" &&
              "appearance-none [-moz-appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          )}
          defaultValue={defaultValue ?? undefined}
          min={type === "number" ? 0 : undefined}
          onClick={onClick}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder ?? undefined}
          type={type ?? "text"}
          value={value ?? undefined}
          readOnly={readOnly}
        />
        {inputPostfix}
      </div>
      {error && (
        <UnControlledError
          error={error}
          data-testid={testId ? `${testId}-error` : `${name}-error`}
        />
      )}

      {!error && success && (
        <div className="text-p-sm text-primary-emerald -z-10 -mt-2 w-full rounded-b-lg bg-[#D7FFFB] px-6 pb-4 pt-5 font-bold">
          {success}
        </div>
      )}
    </div>
  );
};
