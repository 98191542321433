/**
 * Represents the InnerLabel component.
 *
 * @component
 * @example
 * ```tsx
 * <InnerLabel position="left">Label Text</InnerLabel>
 * ```
 * @param {Object} props - The component props.
 * @param {string} props.position - The position of the label (left or right).
 * @param {React.ReactNode} props.children - The children to render in the label.
 * @returns {JSX.Element} The rendered `InnerLabel` component.
 */

import clsx from "clsx";
import type { FC, ReactNode } from "react";

interface Props {
  position: "left" | "right";
  children: ReactNode;
  className?: string;
}
export const InnerLabel: FC<Props> = ({ position, children, className }) => (
  <div
    className={clsx(
      position === "left" ? " pl-6" : "pr-6",
      "flex items-center font-sans font-bold text-label-sm whitespace-nowrap",
      className
    )}
  >
    {children}
  </div>
);
