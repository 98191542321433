import Link from "next/link";
import Image from "next/legacy/image";
import LogoFull from "@/public/icons/logo-full.svg";
import LogoNVL from "@/public/icons/logo-nvl.svg";
import LogoVNA from "@/public/icons/logo-vna.svg";
import { Footer as FooterPrerpType } from "@/services/prepr/queries/getLayoutContent";
import NewsletterForm from "./Footer/NewsletterForm";
import FooterLinks from "./Footer/FooterLinks";
import CopyrightFooter from "./Footer/CopyrightFooter";

interface Props {
  footer: FooterPrerpType;
}

const Footer: React.FC<Props> = ({ footer }) => {
  const {
    title: newsletterTitle,
    text: newsletterText,
    button_text: newsletterButton,
    privacy_statement_text: newsletterPrivacy,
  } = footer?.newsletter[0] ?? {};

  return (
    <footer>
      <div className="bg-primary-black desktop-s:px-0 flex justify-center px-4 text-white">
        <div className="max-w-screen-desktop-xl desktop-s:flex desktop-s:px-8 w-full">
          <div className="desktop-s:w-2/3 desktop-s:pr-36">
            <div className="desktop-s:flex desktop-s:border-b desktop-s:border-grey-asphalt mb-10 justify-between">
              <div className="border-grey-asphalt desktop-s:border-0 flex border-b py-12">
                <Link href="/" className="mr-10">
                  <Image src={LogoFull} alt="logo Hiltermann Lease" height="60" />
                </Link>
              </div>
              <div className="border-grey-asphalt desktop-s:border-0 flex items-center border-b py-10">
                <span className="text-p-sm desktop-s:text-p mr-6 font-serif tracking-wide">
                  Aangesloten bij
                </span>
                <div className="mr-6">
                  <Link href="https://nvl-lease.nl/" target="_blank">
                    <Image src={LogoNVL} alt="logo NVL" height="48" width="48" />
                  </Link>
                </div>
                <Link href="https://www.vna-lease.nl/" target="_blank">
                  <Image src={LogoVNA} alt="logo VNA" height="48" width="48" />
                </Link>
              </div>
            </div>
            <FooterLinks footer={footer} />
          </div>
          <NewsletterForm
            title={newsletterTitle || ""}
            text={newsletterText || ""}
            buttonText={newsletterButton || ""}
            privacyStatementText={newsletterPrivacy || ""}
          />
        </div>
      </div>

      <CopyrightFooter
        links={[
          {
            href: "/privacyverklaring",
            label: "Privacyverklaring",
          },
          {
            href: "/cookieverklaring",
            label: "Cookieverklaring",
          },
          {
            href: "/disclaimer",
            label: "Disclaimer",
          },
        ]}
      />
    </footer>
  );
};

export default Footer;
