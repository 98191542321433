import Link from "next/link";

interface Props {
  links?: { href: string; label: string }[] | null | undefined;
}

const CopyrightFooter: React.FC<Props> = ({ links }) => (
  <div className="bg-grey-light tablet:px-0 flex justify-center px-4">
    <div className="justify-left max-w-screen-desktop-xl tablet:flex tablet:px-8 w-full items-center">
      <div className="text-primary-black my-10 flex justify-center flex-col tablet:flex-row items-center">
        {links &&
          links.map(({ href, label }, i) => (
            <Link
              href={href}
              key={`bottom-footer-link-${i}`}
              className="tablet:mr-16 mr-6 font-[600] hover:underline"
            >
              {label}
            </Link>
          ))}
      </div>
      <div className="text-p-sm my-10 text-center">
        Copyright Hiltermann &#169; Lease {new Date().getFullYear()}
      </div>
    </div>
  </div>
);

export default CopyrightFooter;
