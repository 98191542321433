import Link from "next/link";

interface FooterLinkProps {
  slug: string;
  externalUrl?: string;
  children: JSX.Element | string;
  className?: string;
}

const FooterLink = ({ slug, externalUrl, children, className }: FooterLinkProps) => (
  <li className={className}>
    {externalUrl ? (
      <a
        href={externalUrl}
        className="hover:underline"
        data-gtm-id="footer_navigation"
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link href={slug} className="hover:underline" data-gtm-id="footer_navigation">
        {children}
      </Link>
    )}
  </li>
);

export default FooterLink;
