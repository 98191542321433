"use client";
import clsx from "clsx";
import React, { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ChevronDown } from "../../icons";
import { UnControlledError } from "../Error";
import { Label } from "../Label";

type classNames = {
  container?: string;
  label?: string;
  select?: string;
};

export interface ControlledSelectProps {
  name: string;
  classNames?: classNames;
  children: React.ReactNode;
  error?: string;
  disabled?: boolean;
  labelTexts?: {
    label: string;
    labelDescription?: string;
    optionalLabel?: string;
  };
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  errorFormatter?: (value: string) => string;
}

export const ControlledSelect: React.FC<ControlledSelectProps> = ({
  name,
  classNames,
  children,
  disabled,
  labelTexts,
  error,
  placeholder,
  onChange,
  errorFormatter,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const errorMessage = error ?? fieldState.error?.message ?? "";
        const formattedError =
          errorFormatter && errorMessage ? errorFormatter(errorMessage) : errorMessage;

        return (
          <Select
            {...field}
            onChange={(e) => {
              onChange && onChange(e);
              field.onChange(e);
            }}
            labelTexts={labelTexts}
            classNames={classNames}
            error={formattedError}
            disabled={disabled}
            placeholder={placeholder}
          >
            {children}
          </Select>
        );
      }}
    />
  );
};

interface SelectProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  children: React.ReactNode;
  classNames?: classNames;
  labelTexts?: {
    label: string;
    labelDescription?: string;
    optionalLabel?: string;
  };
  error?: string;
  disabled?: boolean;
  dataGtmId?: string;
  placeholder?: string;
}

export const Select: React.FC<SelectProps> = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      children,
      error,
      classNames = {},
      disabled,
      onChange,
      value,
      name,
      labelTexts,
      dataGtmId,
      placeholder,
      ...props
    },
    ref
  ) => {
    const id = useId();

    const styles = error
      ? "border-secondary-aubergine text-secondary-aubergine"
      : "border-grey-asphalt";

    return (
      <div className={clsx(classNames.container)}>
        {labelTexts && labelTexts.label && (
          <Label
            label={labelTexts.label}
            description={labelTexts.labelDescription}
            id={id}
            optionalLabelText={labelTexts.optionalLabel}
            className={clsx(classNames.label)}
          />
        )}
        <div className="relative rounded-lg">
          <select
            {...props}
            id={id}
            value={value}
            onChange={onChange}
            ref={ref}
            disabled={disabled}
            className={clsx(
              `bg-white text-forms-sm placeholder:text-grey-asphalt placeholder-shown:border-grey-asphalt hover:placeholder:text-primary-black
               focus:border-secondary-aubergine tablet:text-forms-md box-border w-full appearance-none overflow-hidden text-ellipsis rounded-lg border
                bg-no-repeat py-[19px] pl-[24px] pr-[48px] font-sans font-semibold outline-none placeholder:font-normal placeholder-shown:font-normal
                hover:border-2 hover:py-[18px] hover:pl-[23px] focus:border-2 focus:py-[18px] focus:pl-[23px] focus:font-bold`,
              disabled && "text-grey-asphalt",
              styles,
              classNames.select,
              value ? "text-primary-black" : "text-grey-asphalt"
            )}
            data-gtm-id={dataGtmId}
          >
            {placeholder && (
              <option value="" disabled selected>
                {placeholder}
              </option>
            )}
            {children}
          </select>
          <div
            className={clsx(
              "align-center pointer-events-none absolute inset-y-0 right-5 z-0 flex h-16",
              disabled && "opacity-50"
            )}
          >
            <ChevronDown width={18} />
          </div>
        </div>
        {error && (
          <UnControlledError error={error} data-testid={name + "-error"} className="bg-white" />
        )}
      </div>
    );
  }
);
